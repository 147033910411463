// src/pages/RegisterPage.jsx
import React from "react";
import RegisterForm from "./Components/RegisterForm";

const RegisterPage = () => {
  return (
    <>
      <RegisterForm />
    </>
  );
};

export default RegisterPage;
